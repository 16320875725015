import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  initialize() {
    this.counter = document.createElement("span")
    this.counter.classList.add("tweet-count")
    this.contentLimit = 250
  }

  connect() {
    this.sourceTarget.parentNode.getElementsByTagName("label")[0].append(this.counter)
    this.count()
  }

  count() {
    var length = this.sourceTarget.value.length
    this.counter.innerText = " (count: " + length + ")"
    if (length > this.contentLimit) {
      this.counter.classList.add("too-long")
    } else {
      this.counter.classList.remove("too-long")
    }
  }
}
